import {
  PUT,
  POST,
  getFormValue,
  handleViolations,
  buildId, getUrlParam,
  ts, hasViolation, GET, isHospitalStaff
} from '../common';
import DocumentUploader from "./../documents/document-uploader";
import { USER, HTTP, PROFILE } from '../utils';
import ProfileUpdater from "./profile-updater";
import HealthCardReader from "../right_panel/health-card-reader";
import $ from "jquery";
import ConflictManager from "./conflict-manager";
import { PATIENT_HELPER_READING_HEALTH_CARD, trans, USER_TITLE_MADAM, USER_TITLE_SIR } from "../../../translator";

/**
 *
 */
export default class PatientUpdater extends ProfileUpdater {



  violationsMapping = {
    "email": "user.email"
  };


  healthCardReader;

  query = {};

  readHealthCard = false;


  constructor(name) {
    super(name);
    this.$loader = $('.loader');

  }


  init() {

    this.healthCardReader = new HealthCardReader();
    this.healthCardReader.title = trans(PATIENT_HELPER_READING_HEALTH_CARD, {}, 'patient');
    this.healthCardReader.$openButton = $('#read-health-card-button');
    this.healthCardReader.onPatientSelected = (healthCardData) => {
      this.onHealthCardRead(healthCardData);
    };
    this.healthCardReader.init();

    if (getUrlParam('from_health_card')) {
      this.healthCardReader.open();
    }

    this.setupToggleCommunicationControls();

    super.init();

  }

  async setupToggleCommunicationControls() {

    const $blockAllCheckbox = $('#app_patientcreate_blockAllCommunications');
    const $sendSmsCheckbox = $('#app_patientcreate_sendInvitationSMS');
    const $sendEmailCheckbox = $('#app_patientcreate_sendInvitationEmail');

    if(!$blockAllCheckbox.length && !$sendEmailCheckbox.length && !$sendSmsCheckbox) {
      return;
    }

    if(!isHospitalStaff()) {
      return;
    }

    try {
      // Make an API call to retrieve the settings
      const data = await GET(Routing.generate('api_settings_patient_get'),null,null,null,null);

      if(!data.blockAllCommunications && !data.sendInvitationSMS){
        data.sendInvitationEmail = true;
      }

      // Set the checkbox states based on the API response
      $blockAllCheckbox.prop('checked', data.blockAllCommunications);
      $sendSmsCheckbox.prop('checked', data.sendInvitationSMS);
      $sendEmailCheckbox.prop('checked', data.sendInvitationEmail);

      $blockAllCheckbox.on('change', function() {
        if (this.checked) {
          $sendSmsCheckbox.prop('checked', false);
          $sendEmailCheckbox.prop('checked', false);
        }
      });

      $sendSmsCheckbox.on('change', function() {
        if (this.checked) {
          $blockAllCheckbox.prop('checked', false);
        }
      });

      $sendEmailCheckbox.on('change', function() {
        if (this.checked) {
          $blockAllCheckbox.prop('checked', false);
        }
      });

    } catch (error) {
      console.error('Error fetching patient settings:', error);
    }
  }

  async getFormData() {

    let p = getFormValue(this.$form, this.name);
    return await this.parseFormData(p);

  }

  async parseFormData(p) {

    p = this.handleMedicalTeam(p);

    p = await this.handleUser(p);

    p = await this.handleProfileProperties(p);

    // XCS token
    delete p._token;

    return p;
  }


  handleBadRequest(e) {

    handleViolations(e, this.name, this.$form, this.violationsMapping);

    if (hasViolation(e, "socialSecurityNumber")) {
      const ssnInput = this.$form.find(`#${this.name}_socialSecurityNumber`);
      ssnInput.prop('readonly', false);
      ssnInput.closest('.form-row').show();
    }


  }


  /**
     *
     * @param p
     */
  handleProfileProperties(p) {


    let keys = Object.keys(p);

    ['sex', 'hairColor', 'eyesColor', 'laterality', "drinkingFrequency", "familySituation"].forEach((key) => {
      if (keys.indexOf(key) !== -1) {
        p[key] = p[key] ? parseInt(p[key]) : null;
      }
    });


    ['mutual', 'bloodType', 'mutualNumber'].forEach((key) => {
      if (keys.indexOf(key) !== -1) {
        if (!p[key]) {
          p[key] = null;
        }
      }
    });

    return p;

  }


  /**
     *
     * @param p
     * @return {Promise<*>}
     */
  async handleUser(p) {
    if (p.user) {
      // eslint-disable-next-line no-undef
      if (profile && profile.user && profile.user.id) {
        // eslint-disable-next-line no-undef
        p.user.id = buildId('users', profile.user.id);
      } else {
        p.user.role = USER.ROLES.ROLE_PATIENT;
        p.onboarded = true;
      }

      p.user = await this.handleUpload(p.user, "profilePicture");

      p = this.handleDateOfBirth(p);
      p = this.handlePhones(p);
      p = this.handleAddresses(p);

      if (p.user.services) {
        let services = [];
        p.user.services.forEach((service) => {
          services.push(buildId('services', service.id ?? service));
        });

        p.user.services = services;

      }
    }

    return p;
  }


  /**
     *
     * @param p
     * @return {{medicalTeam}|*}
     */
  handleMedicalTeam(p) {
    if (p.medicalTeam) {
      let medicalTeam = [];

      p.medicalTeam.forEach((item) => {
        medicalTeam.push(buildId("users", item.data.user.id));
      });

      p.medicalTeam = medicalTeam;

    }

    return p;
  }

  async save(p, silently) {

    try {
      let d;

      p.readHealthCard = this.readHealthCard;

      // eslint-disable-next-line no-undef
      if (profile.id) {
        // eslint-disable-next-line no-undef
        d = await PUT(Routing.generate('api_patients_put_item', { ...this.query,id: profile.id }), p);
      } else {
        d = await POST(Routing.generate('api_patients_post_collection',this.query), p);
      }
      if (!silently) {
        this.$loader.hide();
      }

      return d;

    } catch (e) {
      this.onError(p,e);
    }
  }


  onError(data,e) {

    const response = e.responseJSON;

    switch (e.status) {
      // Handle conflict entity : A unique value is already used on another account
      case HTTP.STATUS_CODE.CONFLICT :

        this.conflictManager = new ConflictManager(data);
        if(this.healthCardReader.owner) {
          this.conflictManager.owner = this.healthCardReader?.owner['@id'];
        }
        this.conflictManager.handleConflict(response, data);
        break;
      case HTTP.STATUS_CODE.BAD_REQUEST :
        this.handleBadRequest(e);
        break;
    }

    throw e;
  }


  onHealthCardRead(healthCardData) {

    this.healthCardReader.close();

    this.readHealthCard = true;

    if(this.healthCardReader.owner) {
      this.query.owner = this.healthCardReader.owner['@id'];
    }

    const date = new Date(healthCardData.dateOfBirth);

    this.$form.find(`#${this.name}_user_firstName`).val(healthCardData.firstName);
    this.$form.find(`#${this.name}_user_lastName`).val(healthCardData.lastName);
    this.$form.find(`#${this.name}_user_dateOfBirth_day`).val(date.getDate());
    this.$form.find(`#${this.name}_user_dateOfBirth_month`).val(date.getMonth() + 1);
    this.$form.find(`#${this.name}_user_dateOfBirth_year`).val(date.getFullYear());

    const owner = this.healthCardReader.owner;

    if(owner) {
      const $field = this.$form.find(`#${this.name}_medicalTeam`);
      if ($field && $field.length) {
        const ownerAutocompletor = $field.prop('autocompletor');
        ownerAutocompletor.addTag(owner.id, owner.fullName, { user: owner });
      }
    }

    if(healthCardData.sex) {
      this.$form.find('#app_patientcreate_user_title')
        .val(healthCardData.sex === PROFILE.SEX.MALE ?
          trans(USER_TITLE_SIR, {}, 'common') : trans(USER_TITLE_MADAM, {}, 'common')
        );
    }
    if (healthCardData.socialSecurityNumber) {
      const ssnInput = this.$form.find(`#${this.name}_socialSecurityNumber`);

      ssnInput.val(healthCardData.socialSecurityNumber);
      ssnInput.prop('readonly', true);
      ssnInput.closest('.form-row').hide();

    }

    if (healthCardData.address) {
      this.$form.find(`#${this.name}_user_address_address`).val(healthCardData.address);
      this.$form.find(`#${this.name}_user_address_postalCode`).val(healthCardData.postalCode);
      this.$form.find(`#${this.name}_user_address_city`).val(healthCardData.city);
      this.$form.find(`#${this.name}_user_address_country`).val("FR");

      this.$form.find(`#${this.name}_user_address_autocomplete`)
        .val(`${healthCardData.address}, ${healthCardData.postalCode}, ${healthCardData.city}`);

      // Display the form
      this.$form.find('.not-found-link').hide();

      this.$form.find(`${this.name}_user_email`).focus();

    }

    // scroll to the bottom of the page
    $("html, body").animate({ scrollTop: $(document).height() }, 1000);

  }

  /**
     *
     * @param file
     * @return {Promise<void>}
     */
  async uploadFile(file) {
    return DocumentUploader.upload(file);
  }


  static fillPatientFiles(patient) {
    $('.profile_socialSecurityNumber span').html(patient.socialSecurityNumber);

    PatientUpdater.fillDateOfBirth(patient.user,patient.sex);

  }


  static fillDateOfBirth(user,sex) {

    // eslint-disable-next-line no-prototype-builtins
    if (!user.hasOwnProperty("dateOfBirth")) {
      return;
    }

    $('.user_dateOfBirth span').html(PatientUpdater.formatAgeText(user.dateOfBirth,sex));

  }

  static formatAgeText(date,sex) {
    if (!date) {
      return "";
    }

    if (typeof date === "string") {
      date = new Date(date);
    }

    const age = PatientUpdater.formatAge(date);

    if (!age) {
      return "";
    }


    return ts(`profile.born_on${sex ? `.${sex}` : ''}`,
      { "{{ date }}": date.toLocaleDateString(), "{{ age }}": age });

  }


  static
  formatAge(dateOfBirth, currentDate = new Date()) {
    if (!(dateOfBirth instanceof Date)) {
      return "";
    }

    let yearDiff = currentDate.getFullYear() - dateOfBirth.getFullYear();
    let monthDiff = currentDate.getMonth() - dateOfBirth.getMonth();
    const dayDiff = currentDate.getDate() - dateOfBirth.getDate();

    if(dayDiff < 0 && monthDiff === 0) {
      monthDiff--;
    }

    if(monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      yearDiff--;
      monthDiff += 12;
      monthDiff %= 12;
    }

    if (yearDiff >= 7) {
      return ts("age.years", {
        "{{ years }}": yearDiff
      }, "user");
    }

    if (yearDiff < 7 && yearDiff >= 1) {
      if (monthDiff === 0) {
        return ts("age.years", {
          "{{ years }}": yearDiff
        }, "user");
      }

      return ts("age.years_and_month", {
        "{{ years }}": yearDiff,
        "{{ month }}": monthDiff
      }, "user");
    } else {
      if (yearDiff === 0 && monthDiff === 0) {
        return ts("age.days", {
          "{{ days }}": dayDiff
        }, "user");
      }
      return ts("age.month", {
        "{{ month }}": monthDiff
      }, "user");
    }
  }

}
