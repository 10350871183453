import $ from "jquery";
import { TASK } from "../utils";
import { displaySuccess, POST } from "../common";
import { trans, USER_HELPER_MERGED, USER_HELPER_REQUEST_TAKEN } from "../../../translator";

export default class ConflictManager {

  conflictModal = $('#conflict_modal');
  mergeModal = $('#merge_modal');

  // Used fo impersonation in the health card flow
  owner;

  patient;

  constructor(patient) {
    this.patient = patient;
  }


  onRedirect = (profile) => {
    document.location.href = Routing.generate('app_patient_user_profile', { id: profile.user.id });
  };

  save = async (formData) => formData;

  handleConflict(response, formData) {

    let { field, object } = response;

    if (object) {
      this.handleMerge(response, formData);
      return;
    }


    this.conflictModal.fadeIn(200);

    this.conflictModal.find('.yes').off('click').on('click', async () => {

      this.conflictModal.find('.loader').show();

      this.patient = await this.saveConflictData(field, formData);

      let data = {
        type: TASK.TYPE.MERGE_REQUEST,
        subject: this.patient.user.id,
        field: field,
        fieldValue: this.patient[field]
      };

      await POST(Routing.generate('api_tasks_post_collection'), data);

      this.conflictModal.find('.loader').hide();

      displaySuccess(trans(USER_HELPER_REQUEST_TAKEN, {}, 'user'));

      this.conflictModal.hide();

      this.onRedirect(this.patient);

    });

    this.conflictModal.find('.no').off('click').on('click', () => {
      this.conflictModal.hide();
    });

  }

  handleMerge(response, formData) {

    let { field, object } = response;

    this.mergeModal.fadeIn(200);

    this.mergeModal.find('.yes').off('click').on('click', async () => {

      this.mergeModal.find('.loader').show();

      if (formData) {
        this.patient = await this.saveConflictData(field, formData);
      }

      const query = {};

      if (this.owner) {
        query.owner = this.owner;
      }

      const existing = await POST(Routing.generate('api_users_merge', query), {
        user1: object,
        user2: this.patient.user.id
      });

      displaySuccess(trans(USER_HELPER_MERGED, {}, 'user'));

      this.onRedirect({
        user: existing
      });

    });

    this.mergeModal.find('.no').off('click').on('click', () => {
      this.mergeModal.hide();
    });

  }


  async saveConflictData(field, formData) {

    const fieldValue = this.getConflictedField(formData, field);

    formData = this.deleteConflictedField(formData, field);

    const profile = await this.save(formData, true);

    profile[field] = fieldValue;

    return profile;
  }


  /**
     *
     * @param object
     * @param field
     * @return {*}
     */
  getConflictedField(object, field) {
    switch (field) {
      case "email" :
        return object.user ? object.user[field] : object[field];
      default :
        return object[field];
    }

  }


  /**
     *
     * @param object
     * @param field
     * @return {{user}|*}
     */
  deleteConflictedField(object, field) {
    if (object.user && object.user[field]) {
      delete object.user[field];
    }

    if (object[field]) {
      delete object[field];
    }

    return object;
  }


}
