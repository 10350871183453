import { closeDrawer, openDrawer } from "../rightPanel";
import { GET, POST, PUT } from "../common";

export default class DrawerManager extends EventTarget {

  title;
  $drawer;
  $openButton;
  currentRequest;

  panels = [];

  init() {
    this.registerEvents();
  }

  registerEvents() {
    if(this.$openButton) {
      this.$openButton.off('click').on('click', (e) => {
        e.preventDefault();
        this.open();
      });
    }

    if(this.$drawer.get(0)) {
      this.$drawer.get(0).onClose = (e) => {
        this.onClose(e);
      };
    }

  }

  async open() {
    this.$drawer.find('.supervision-item').hide();
    this.$drawer.find('.drawer-header h2').html(this.title);
    openDrawer(this.$drawer);

  }

  checkPanel(panelId, subtitle, blockOpening) {
    this.setPanelStatus(panelId,subtitle,blockOpening,"check");
  }


  setPanelStatus(panelId, subtitle, blockOpening,status) {

    const panel = this.getPanel(panelId);

    panel.show();

    panel.find(`.loader`).hide();
    panel.find(`.check`).hide();
    panel.find(`.error`).hide();
    panel.find(`.${status}`).show();
    const checkbox = panel.find(`.toggle_input`);
    checkbox.prop('checked', false);
    checkbox.prop('disabled', !!blockOpening);

    if (subtitle) {
      panel.find('.subtitle').html(subtitle);
    }

  }


  errorPanel(panelId, subtitle, blockOpening) {
    this.setPanelStatus(panelId,subtitle,blockOpening,"error");
  }

  openPanel(panelId, subtitle, isLoading) {

    this.resetAlerts();

    // Close other panels
    this.$drawer.find('.toggle_input').prop("checked", false);

    const panel = this.getPanel(panelId);

    const checkbox = panel.find(`.toggle_input`);
    panel.find(`.check`).hide();
    panel.find(`.error`).hide();

    if (isLoading) {
      panel.find(`.loader`).show();
      checkbox.prop('disabled', true);
    } else {
      panel.find(`.loader`).hide();
      checkbox.prop('disabled', false);
      checkbox.prop('checked', true);
    }


    if (subtitle) {
      panel.find('.subtitle').html(subtitle);
    }

    panel.show();

  }



  getPanel(panelId) {

    if (this.panels[panelId]) {
      return this.panels[panelId];
    }

    this.panels[panelId] = this.$drawer.find(`#supervision_item_${panelId}`).closest(".supervision-item");

    return this.panels[panelId];
  }


  resetAlerts() {
    this.$drawer.find('.popin_alerts').html('');
  }

  async close() {
    closeDrawer(this.$drawer);
  }


  async POST(route, data, headers, options) {
    const response = await POST(route, data, this.$drawer, headers, (req) => {
      this.currentRequest = req;
    }, options);

    this.currentRequest = null;

    return response;

  }

  async GET(route, headers, options) {
    const response = await GET(route, this.$drawer, headers, (req) => {
      this.currentRequest = req;
    }, options);

    this.currentRequest = null;

    return response;

  }


  async PUT(route, data, headers, options) {
    const response = await PUT(route, data, this.$drawer, headers, (req) => {
      this.currentRequest = req;
    }, options);

    this.currentRequest = null;

    return response;

  }

  onClose() {
    if (this.currentRequest && this.currentRequest.abort) {
      this.currentRequest.abort();
      this.currentRequest = null;
    }
  }


}
