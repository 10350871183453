import { buildId, displayError, GET, isDoctor, parseId, POST, PUT, v2DisplayImage } from "../common";
import { HTTP } from "../utils";
import TagsAutocompleter from "../TagsAutocompleter";
import DrawerManager from "./drawer-manager";
import {
  COMMON_WORDS_YEARS,
  PATIENT_CONSULTATIONS_CREATED_FOR, PATIENT_CONSULTATIONS_SELECT_DOCTOR,
  PATIENT_CONSULTATIONS_SELECT_OWNER, PATIENT_CONSULTATIONS_SELECT_PATIENT,
  PATIENT_CREATED_FOR,
  PATIENT_DOCTOR_LIST_LOADING,
  PATIENT_HELPER_NO_DOCTOR_LINKED, PATIENT_HELPER_READ_HEALTH_CARD,
  PATIENT_HELPER_READING_DONE,
  PATIENT_HELPER_READING_ERROR,
  PATIENT_HELPER_READING_HEALTH_CARD, PATIENT_HELPER_SELECT_BENEFICIARIES, PATIENT_HELPER_USER_SELECTED,
  PATIENT_LIST_DOCTORS,
  PATIENT_TELETRANSMISSION_OFF,
  trans, USER_HELPER_ASK_RECONNECT, USER_HELPER_CLICK_TO_RECONNECT, USER_HELPER_LINK_LOADING
} from "../../../translator";

export default class HealthCardReader extends DrawerManager {


  isConsultationFlow = false;

  // @deprecated; used in the flow where the assistant does not have its own Stellair account
  owner = null;

  profile;

  currentMedic = null;

  PANEL_HEALTH_CARD = "health_card_read";
  PANEL_RECONNECT = "reconnect";
  PANEL_MANUAL_OWNER_SELECTION = "manual_owner_selection";
  V2_PANEL_MANUAL_OWNER_SELECTION = "v2_manual_owner_selection";
  PANEL_PATIENT_SELECTION = "patient_selection";

  $drawer = $('#read-health-card-drawer');


  async open(init = true) {

    super.open();

    if(init) {
      if (isDoctor()) {
        this.readHealthCard();
      } else {
        this.fillFConsultationOwnerModal();
      }
    }

  }

  async v2FillConsultationOwnerModal() {
    const panelId = this.V2_PANEL_MANUAL_OWNER_SELECTION;

    const panel = this.getPanel(panelId);
    const button = panel.find('.continue');
    button.prop('disabled', true);

    this.openPanel(panelId, trans(PATIENT_LIST_DOCTORS, {}, 'patient'), true);

    const $input = panel.find('#v2_drawer_consultation_owner');

    let tagsInput = $input.prop('autocompletor');
    if(!tagsInput) {
      tagsInput = new TagsAutocompleter(panel.find('#v2_drawer_consultation_owner'));
      tagsInput.throwOnError = true;
      await tagsInput.init();
    }

    try {
      await tagsInput.loadValues(true);
      const data = tagsInput.getData();

      if(data.length === 0) {
        this.openPanel(panelId, trans(PATIENT_HELPER_NO_DOCTOR_LINKED, {}, 'patient'));
        return;
      }

      if (data.length === 1) {
        this.currentMedic = data[0];
        this.checkPanel(panelId,
          trans(PATIENT_CONSULTATIONS_CREATED_FOR, { '$name': this.currentMedic.fullName }, 'patient'), true);
        this.readHealthCard();
        return;
      }

    } catch (e) {
      console.log(e);

      const message = e.responseJSON['hydra:description'];
      const statusCode = e && e.status ? e.status : null;

      if(statusCode === HTTP.STATUS_CODE.UNAUTHORIZED) {

        const panel = this.getPanel(panelId);
        panel.hide();
        this.displayReconnectPanel(() => {
          this.v2FillConsultationOwnerModal();
        });
        displayError(message,this.$drawer);
        return;
      }

    }

    this.openPanel(panelId, trans(PATIENT_CONSULTATIONS_SELECT_OWNER, [], 'patient'));

    tagsInput.clear();

    tagsInput.onItemAdd = () => {
      button.prop('disabled', false);
    };

    tagsInput.onItemRemove = () => {
      button.prop('disabled', false);
    };

    button.off('click').on('click', async () => {

      this.currentMedic = await tagsInput.value.data;

      if(!this.currentMedic.current) {
        this.openPanel(panelId,
          trans(PATIENT_CONSULTATIONS_CREATED_FOR, { '$name': this.currentMedic.fullName }, 'patient'), true);

        try {
          this.currentMedic = await PUT(Routing.generate('api_patient_billing_medics_put_item', {
            id: parseId(this.currentMedic['@id'])
          }), {
            current: true
          });

        } catch (e) {
          this.errorPanel(panelId,e['hydra:description'],false);
          return;
        }
      }

      this.checkPanel(panelId,
        trans(PATIENT_CONSULTATIONS_CREATED_FOR, { '$name': this.currentMedic.fullName }, 'patient'));
      this.readHealthCard();
    });

  }

  async fillFConsultationOwnerModal() {
    const panelId = this.PANEL_MANUAL_OWNER_SELECTION;

    const panel = this.getPanel(panelId);

    this.openPanel(panelId, trans(PATIENT_DOCTOR_LIST_LOADING), true);

    this.profile = await POST(Routing.generate('api_patient_billing_profiles_post_collection'));

    if(this.profile.loggedIn) {
      panel.hide();
      return this.v2FillConsultationOwnerModal();
    }

    const button = panel.find('.continue');
    button.prop('disabled', true);

    const tagsInput = new TagsAutocompleter(panel.find('#drawer_consultation_owner'));

    await tagsInput.init();

    const data = await tagsInput.getData();

    if (data.length === 1) {
      this.owner = data[0];

      const subtitle = this.isConsultationFlow ?
        trans(PATIENT_CONSULTATIONS_CREATED_FOR, { '$name': this.owner.fullName }, 'patient') :
        trans(PATIENT_CREATED_FOR, { '$name': this.owner.fullName }, 'patient');

      this.checkPanel(panelId, subtitle, true);
      this.readHealthCard();
      return;
    }


    const subtitle = this.isConsultationFlow ?
      trans(PATIENT_CONSULTATIONS_SELECT_OWNER, {}, 'patient') :
      trans(PATIENT_CONSULTATIONS_SELECT_DOCTOR, {}, 'patient');
        

    this.openPanel(panelId, subtitle);

    tagsInput.clear();

    tagsInput.onItemAdd = () => {
      button.prop('disabled', false);
    };

    tagsInput.onItemRemove = () => {
      button.prop('disabled', false);
    };

    button.off('click').on('click', async () => {
      this.owner = await tagsInput.value.data;

      const subtitle = this.isConsultationFlow ?
        trans(PATIENT_CONSULTATIONS_CREATED_FOR, { '$name': this.owner.fullName }, 'patient') :
        trans(PATIENT_CREATED_FOR, { '$name': this.owner.fullName }, 'patient');


      this.checkPanel(panelId, subtitle);
      this.readHealthCard();
    });


  }


  async readHealthCard() {

    const panelId = this.PANEL_HEALTH_CARD;

    this.openPanel(panelId, trans(PATIENT_HELPER_READING_HEALTH_CARD, {}, 'patient'), true);

    // Hide manual data
    this.getPanel(this.PANEL_MANUAL_DATA).hide();

    let data;

    const query = this.addOwnerToQuery({});

    try {
      data = await this.GET(
        Routing.generate("api_patient_billing_patients_get_collection", query)
        ,null, { silently: true });
    } catch (e) {

      const message = e.responseJSON['hydra:description'];

      const statusCode = e && e.status ? e.status : null;

      if (statusCode === HTTP.STATUS_CODE.PRECONDITION_REQUIRED) {
        this.fillMissingHealthCardModal();
        displayError(message,this.$drawer);
        return;
      }

      if(statusCode === HTTP.STATUS_CODE.BAD_REQUEST) {
        this.fillMissingHealthCardModal();
        this.errorPanel(panelId,message,false);
        this.openPanel(panelId);
        displayError(message,this.$drawer);
        displayError(message);
        return;
      }

      if(statusCode === HTTP.STATUS_CODE.FORBIDDEN) {
        this.errorPanel(panelId,isDoctor() ? trans(PATIENT_TELETRANSMISSION_OFF, {}, 'patient') : message,true);
        this.fillEnterDataManually();
        displayError(message,this.$drawer);
        return;
      }

      if(statusCode === HTTP.STATUS_CODE.UNAUTHORIZED) {

        const panel = this.getPanel(panelId);
        panel.hide();
        this.displayReconnectPanel();
        displayError(message,this.$drawer);
        return;
      }


      throw e ? e : new Error(trans(PATIENT_HELPER_READING_ERROR, {}, 'patient'));
    }

    this.checkPanel(panelId, trans(PATIENT_HELPER_READING_DONE, {}, 'patient'), true);

    if (data['hydra:member'].length === 1) {
      this.onPatientSelected(data['hydra:member'][0]);
      return;
    }

    this.fillHealthCardModal(data['hydra:member']);

  }


  async displayReconnectPanel(onReset) {
    const panelId = this.PANEL_RECONNECT;

    // I am an assistant, this flow does not work yet
    if(this.owner) {
      this.errorPanel(panelId, trans(USER_HELPER_ASK_RECONNECT, { '$name': this.owner.fullName }, 'user'),true);
      return;
    }

    this.openPanel(panelId, trans(USER_HELPER_LINK_LOADING, {}, 'user'), true);

    if(!this.profile) {
      this.profile = await GET(Routing.generate('api_patient_billing_profiles_profile_me_item'));
    }
    this.openPanel(panelId, trans(USER_HELPER_CLICK_TO_RECONNECT, {}, 'user'));

    const panel = this.getPanel(panelId);

    panel.find('.reconnect').attr('href',this.profile.oauthUrl);

    panel.find('.reset').off('click').on('click',() => {
      panel.hide();
      if(typeof onReset === "function") {
        onReset();
      }else {
        this.readHealthCard();
      }
    });


  }


  async fillMissingHealthCardModal() {
    const panelId = this.PANEL_HEALTH_CARD;

    this.openPanel(panelId, trans(PATIENT_HELPER_READ_HEALTH_CARD, {}, 'patient'));

    const panel = this.getPanel(panelId);

    panel.find(".enter-data-manually").off('click').hide();

    panel.find('.read-health-card').off('click').on('click', () => {
      this.readHealthCard();
    });


  }

  fillEnterDataManually() {
    const panelId = this.PANEL_MANUAL_DATA;

    this.openPanel(panelId, trans(PATIENT_CONSULTATIONS_SELECT_PATIENT, {}, 'patient'));

    const panel = this.getPanel(panelId);
    const button = panel.find('.continue');
    button.prop('disabled', true);

    const tagsInput = new TagsAutocompleter(panel.find('#drawer_consultation_patient'));

    tagsInput.init();

    tagsInput.clear();

    tagsInput.onItemAdd = () => {
      button.prop('disabled', false);
    };

    tagsInput.onItemRemove = () => {
      button.prop('disabled', false);
    };

    button.off('click').on('click', async () => {

      const patient = await tagsInput.value.data;

      this.checkPanel(panelId, trans(PATIENT_HELPER_USER_SELECTED, { '$name': patient.user.fullName }, 'patient'));
      this.createConsultationForToday(patient);
    });

  }

  fillHealthCardModal(patients) {

    const panelId = this.PANEL_PATIENT_SELECTION;

    this.openPanel(panelId, trans(PATIENT_HELPER_SELECT_BENEFICIARIES, {}, 'patient'));

    this.$drawer.find('.patient-selection').html('');

    patients.forEach((patient) => {
      const $template = $($("#patient-selection-template").html());

      $template.find('.name').html(patient.fullName);
      $template.find('.age').html(`${patient.age} ` + trans(COMMON_WORDS_YEARS, {}, 'common'));
      $template.find('.dob').html(new Date(patient.dateOfBirth).toLocaleDateString());
      $template.find('.ssn').html(patient.socialSecurityNumber);
      v2DisplayImage(null, $template.find('.avatar'), null, patient);

      $template.off('click').on('click', (e) => {
        e.preventDefault();
        this.checkPanel(panelId, trans(PATIENT_HELPER_USER_SELECTED, { '$name': patient.fullName }, 'patient'));
        this.onPatientSelected(patient);
      });

      this.$drawer.find('.patient-selection').append($template);

    });

    this.$drawer.find("#patient_selection").show();


  }


  addOwnerToQuery(query, addCurrentUser) {

    if (!this.owner) {

      if (addCurrentUser) {
        if(this.currentMedic) {
          query.owner = buildId("users", this.currentMedic.userId);
        } else {
          query.owner = buildId("users", current_user.id);
        }
      }

      return query;
    }

    query.owner = buildId("users", this.owner['@id']);

    return query;

  }

  onPatientSelected() {

  }


}
