import { isMobileSize } from "./common";
import $ from "jquery";

const sidebarRightCls = '.sidebar-right';

/**
 * Check if rightPanel its open
 * @return {Boolean} Its open value
 */
export const isOpen = () => $(sidebarRightCls).css('right') !== '0px';

/**
 * Block body scroll
 * @param  {bool} blocked  Blocked the body scrool ?
 */
export const toggleBodyOverflow = (blocked) => {
  blocked && $(document.body).addClass('overflow-hidden');
  !blocked && $(document.body).removeClass('overflow-hidden');
};

/**
 * Open the right panel
 */
export const open = () => {
  toggleBodyOverflow(true);
  $(sidebarRightCls).eq(0).animate({ right: '300px' });
};

/**
 * Close the right panel
 */
export const close = (onComplete) => {
  $(sidebarRightCls).eq(0).animate({ right: '0' }, { complete: onComplete });
  toggleBodyOverflow();
};

/**
 * Close all child of right panel
 */
export const hideChild = () => {
  close(() => $('.right_panel_child').hide());
};

/**
 * Init
 */
export default () => {
  $('.sidebar-right-close').click(hideChild);
};


export const openDrawer = ($drawer, width) => {

  if ($drawer.is(':visible')) {
    return;
  }


  $('.the-content').addClass('no-pointer');

  $drawer.css('display', 'flex');

  if (!width) {
    width = '600px';
  }

  if (isMobileSize()) {
    width = '100%';
  }

  $drawer.eq(0).animate({ width: width },() => {
    $drawer.trigger('openDrawer');
  });


};


export const addScrollButton = ($container) => {

  const $parent = $container.parent();

  if(!isScrollable($container,$parent)) {
    return;
  }

  $container.remove('.scroll-button');

  const $button = $(`<button class="scroll-button" type="button" >
<span class="material-icons">arrow_drop_down</span>
</button>`);

  $container.append($button);

  $button.on('click',() => {
    $container.animate({ scrollTop: $container.get(0).scrollHeight }, 1000);
  });

  $container.off('scroll').on('scroll',(e) => {
    const elem = $(e.currentTarget);

    // Here uses +5 because some browser don't scroll exactly to the bottom
    const isBottom = elem[0].scrollHeight - elem.scrollTop() <= elem.outerHeight() + 5;

    if(isBottom) {
      //    $button.fadeOut(200);
      $button.css('visibility','hidden');
    } else {
      $button.css('visibility','visible');
      //    $button.fadeIn(200);
    }
  });


};

const isScrollable = ($container,$parent) => {
  return $container.get(0).scrollHeight > $parent.get(0).scrollHeight;
};

export const closeDrawer = ($drawer) => {

  if (!$drawer.is(':visible')) {
    return;
  }

  $('.the-content').removeClass('no-pointer');

  $('body').find('.drawer-sidebar').remove();

  $drawer.eq(0).animate({ width: '0' }, {
    complete: () => {
      $drawer.hide();
    }
  });
};
